<template>
  <v-row dense>
    <v-col>
      <v-text-field
        v-model="title"
        :rules="rules.required"
        label="Name *"
        maxlength="255"
      ></v-text-field>
      <v-text-field
        v-model="number"
        label="Number *"
        :rules="rules.required"
        v-mask="farmNumberMask"
      ></v-text-field>
      <v-text-field
        v-model="latitude"
        label="Latitude"
        v-mask="latitudeMask"
      ></v-text-field>
      <v-text-field
        v-model="longitude"
        label="Longitude"
        v-mask="longitudeMask"
      ></v-text-field>
      <vue-tel-input-vuetify
        v-model="phone"
        :default-country="phoneData.country"
        label="Phone"
        placeholder="Enter Phone Number"
        :key="phoneInputKey"
        @country-changed="onPhoneCountryChanged"
        :rules="rules.phone"
      >
      </vue-tel-input-vuetify>
      <v-text-field
        v-model="email"
        label="Email"
        maxlength="100"
        :rules="rules.email"
      ></v-text-field>
      <v-checkbox v-model="is_active" label="Active"></v-checkbox>
    </v-col>
    <v-col>
      <v-text-field
        v-model="address_1"
        :rules="rules.address"
        label="Address 1"
      ></v-text-field>
      <v-text-field
        v-model="address_2"
        :rules="rules.address"
        label="Address 2"
      ></v-text-field>
      <v-text-field
        v-model="city"
        :rules="rules.address"
        label="City"
      ></v-text-field>
      <v-select
        v-model="country_id"
        :items="countries"
        item-value="id"
        item-text="country_name"
        label="Country"
        no-data-text="No Countries Available"
        @change="onCountrySelectChanged"
      >
      </v-select>
      <v-select
        v-if="filterById('states', country_id, 'country_id').length"
        v-model="state_id"
        :items="filterById('states', country_id, 'country_id')"
        item-value="id"
        item-text="title"
        label="State/Province"
        no-data-text="No States/Provinces Available"
      >
      </v-select>
      <v-text-field
        v-model="postal_code"
        label="Postal Code"
        :rules="rules.postal_code"
      ></v-text-field>
    </v-col>
  </v-row>
</template>

<script src="https://unpkg.com/vue-tel-input@5"></script>
<script>
import Vue from "vue";
import rules from "@/utils/rules";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import { mapGetters } from "vuex";
import { mapFields } from "vuex-map-fields";
import hasIn from "lodash.hasin";

const latitudeMask = createNumberMask({
  prefix: "",
  suffix: "",
  allowDecimal: true,
  includeThousandsSeparator: false,
  integerLimit: 2,
  decimalLimit: 8,
  allowNegative: true,
  allowLeadingZeroes: true
});

const longitudeMask = createNumberMask({
  prefix: "",
  suffix: "",
  allowDecimal: true,
  includeThousandsSeparator: false,
  integerLimit: 3,
  decimalLimit: 8,
  allowNegative: true,
  allowLeadingZeroes: true
});

export default {
  name: "FarmsEditFormFields",
  data() {
    return {
      loading: true,
      rules: {
        required: [rules.required],
        latitude: [rules.maxLength(11)],
        longitude: [rules.maxLength(12)],
        postal_code: [rules.maxLength(10)],
        address: [rules.maxLength(255)],
        email: [rules.email],
        phone: [
          v =>
            /^\s*[-()0-9+ ]+\s*$/.test(v) ||
            v === "" ||
            "Invalid phone number (digits only allowed). Keep adding digits to remove invalid characters"
        ]
      },
      latitudeMask,
      longitudeMask,
      farmNumberMask: "#################",
      phoneData: {
        number: "",
        valid: false,
        country: undefined
      },
      phoneInputKey: 0 // Added to force re-rendering
    };
  },
  computed: {
    ...mapGetters("baseData", ["countries", "states", "filterById"]),
    ...mapFields("farms", [
      "detailItem.id",
      "detailItem.title",
      "detailItem.number",
      "detailItem.is_active",
      "detailItem.address_1",
      "detailItem.address_2",
      "detailItem.city",
      "detailItem.country_id",
      "detailItem.state_id",
      "detailItem.postal_code",
      "detailItem.phone",
      "detailItem.email",
      "detailItem.latitude",
      "detailItem.longitude"
    ]),
    routeSampleId() {
      return hasIn(this.$route, "params.sampleId")
        ? this.$route.params.sampleId
        : null;
    },
    routeFarmId() {
      return hasIn(this.$route, "params.id") ? this.$route.params.id : null;
    }
  },
  watch: {
    country_id(newCountryId) {
      const country = this.countries.find(c => c.id === newCountryId);
      if (country) {
        this.updatePhoneCountry(country);
      }
    },
    phone(newValue) {
      this.phone = newValue.replace(/\D/g, "");
    }
  },
  methods: {
    titleRules() {
      return [
        !!this.title || !!this.number || "Required when no Number is given."
      ];
    },
    numberRules() {
      return [
        !!this.number || !!this.title || "Required when no Name is given."
      ];
    },
    updatePhoneCountry(newCountry) {
      if (newCountry && newCountry.country_code) {
        this.phoneData.country = newCountry.country_code.toLowerCase(); // Ensure it matches the expected format
        // console.log("PhoneData country updated to:", this.phoneData.country);
        this.phoneInputKey += 1; // Force re-rendering
      }
    },
    onPhoneCountryChanged(newCountry) {
      // console.log("Phone component country changed to:", newCountry);
      // Handle any additional logic here if needed
    },
    onCountrySelectChanged(newCountryId) {
      const country = this.countries.find(c => c.id === newCountryId);
      if (country) {
        this.updatePhoneCountry(country);
      }
    }
  },
  mounted() {
    if (this.country_id) {
      const country = this.countries.find(c => c.id === this.country_id);
      if (country) {
        this.updatePhoneCountry(country);
      }
    } else if (this.countries.length > 0) {
      this.country_id = this.countries[0].id; // Set default country ID
      this.updatePhoneCountry(this.countries[0]);
    }
  },
  beforeMount() {
    if (
      this.phone.includes("(") ||
      this.phone.includes(")") ||
      this.phone.includes("-")
    ) {
      this.phone = this.phone.replace(/[\s\-()]/g, " ");
    }
  }
};
</script>
