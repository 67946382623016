<template>
  <FarmsEdit></FarmsEdit>
</template>

<script>
import FarmsEdit from "@/components/newFarms/FarmsEdit";
export default {
  name: "Edit",
  components: { FarmsEdit }
};
</script>

<style scoped></style>
