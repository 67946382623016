<template>
  <LoadingBar v-if="loading" />
  <Page v-else :title="pageTitle">
    <v-form ref="form" v-model="valid" lazy-validation>
      <FarmsEditFormFields></FarmsEditFormFields>
      <v-row class="pa-5">
        <v-btn @click="cancel()" color="secondary">
          Cancel
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="saveFarm()">
          Save
        </v-btn>
      </v-row>
    </v-form>
  </Page>
</template>

<script>
import LoadingBar from "@/components/loaders/LoadingBar";
import Page from "@/components/layout/Page";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import isEmpty from "lodash.isempty";
import FarmsEditFormFields from "@/components/newFarms/FarmsEditFormFields";
import hasIn from "lodash.hasin";

export default {
  name: "FarmsEdit",
  components: {
    FarmsEditFormFields,
    LoadingBar,
    Page
  },
  data() {
    return {
      loading: true,
      valid: false
    };
  },
  computed: {
    ...mapGetters("clients", ["selectedClient"]),
    ...mapState("farms", ["detailItem"]),
    isNew() {
      return !this.detailItem || !this.detailItem.id;
    },
    pageTitle() {
      return this.isNew ? "Add Farm" : "Edit Farm";
    },
    routeFarmId() {
      return hasIn(this.$route, "params.id") ? this.$route.params.id : null;
    }
  },
  methods: {
    ...mapMutations("farms", ["setDetailItem", "clearDetailItem"]),
    ...mapActions("clients", ["getClients"]),
    ...mapActions("farms", ["addFarm", "editFarm", "getFarm"]),
    saveFarm() {
      if (!this.$refs.form.validate()) return false;
      let promise;
      if (this.isNew) {
        promise = this.addFarm(this.detailItem);
      } else {
        promise = this.editFarm(this.detailItem);
      }
      promise.then(() => {
        this.clearDetailItem();
        this.$router.push("/farms/newbrowse");
      });
    },
    cancel() {
      this.clearDetailItem();
      this.$router.push("/farms/newbrowse");
    },
    loadFarm(id) {
      if (id && id !== this.id) {
        this.loading = true;
        this.getFarm(id)
          .then(() => {
            this.loading = false;
          })
          .catch(() => {
            this.$router.push("/farms/newbrowse");
          });
      } else if (!this.routeFarmId) {
        this.clearDetailItem();
        const item = this.detailItem;
        if (isEmpty(item.client_id) || isEmpty(item.lab_id)) {
          this.loading = true;
          this.getClients().then(() => {
            this.setDetailItem(
              Object.assign(item, {
                client_id: this.selectedClient.id,
                lab_id: this.selectedClient.lab_id
              })
            );
            this.loading = false;
          });
        }
      }
    }
  },
  watch: {
    $route(to) {
      this.loadFarm(to.params.id);
    }
  },
  mounted() {
    this.loadFarm(this.$route.params.id);
  }
};
</script>

<style scoped></style>
